<template>
  <span
    v-if="date"
    class="nowrap value"
  >
    <span v-if="showDate">{{dateDisplay}}</span>
    <span
      v-if="((timeline && diffDays === 1) || showTime) && canShowTime"
      class="ml-1"
    >{{timeDisplay}}</span>
  </span>

</template>

<script>
export default {
  computed: {
    dateDisplay () {
      return this.lowerCase ? this.dateValue().toLowerCase() : this.dateValue()
    },
    timeDisplay () {
      return this.timeValue
    },
    timeValue () {
      return this.moment(this.date).format('LT')
    },
    diffDays () {
      return Math.abs(this.moment().startOf('day').diff(this.moment(this.date).startOf('day'), 'days'))
    },
    showDate () {
      return !this.timeline || this.diffDays !== 1
    }
  },
  methods: {
    dateValue () {
      const date = this.moment(this.date)

      if (date.hour() === 0 && date.minute() === 0 && date.second() === 0 && date.millisecond() === 0) {
        this.canShowTime = false
      }

      if (this.timeline && this.diffDays === 0) {
        return date.fromNow()
      }

      if (this.diffDays > 1) {
        return this.formatDate(date)
      }

      if (this.diffDays === 1 || (this.diffDays === 0 && date.hour() === 0 && date.minute() === 0 && date.second() === 0 && date.millisecond() === 0) || date.hour() > 0) {
        return date.calendar(null, this.calendarFormat)
      }

      if (date.hour() > 0) {
        return date.calendar(null, this.calendarFormat)
      }

      const mins = Math.abs(this.moment().diff(date, 'minutes'))

      this.canShowTime = false

      if (mins > 60) {
        return date.format('LT')
      }

      return date.fromNow()
    }
  },
  data () {
    return {
      canShowTime: true,
      calendarFormat: {
        lastDay: `[${this.$t('t.Yesterday')}]`,
        sameDay: `[${this.$t('t.Today')}]`,
        nextDay: `[${this.$t('t.Tomorrow')}]`
      }
    }
  },
  props: {
    date: [Object, String],
    showTime: {
      type: Boolean,
      default: false
    },
    timeline: {
      type: Boolean,
      default: false
    },
    lowerCase: Boolean
  }
}
</script>
